import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import StartIcon from '../Images/start.png';
import StopIcon from '../Images/stop.png';
import redparrot from '../Images/redParrotsmall.ico';
import place1 from '../Images/place.png';
import * as turf from '@turf/turf';

// Helper function to get a cookie by name
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const Map = ({ currentLatitude, currentLongitude }) => {
  const [position, setPosition] = useState([currentLatitude, currentLongitude]);
  const mapRef = useRef(null);
  const stopsLayer = useRef(L.layerGroup());
  const placesLayer = useRef(L.layerGroup());
  const routingControlRef = useRef(null);
  const destinationMarkerRef = useRef({ startMarker: null, stopMarker: null });

  useEffect(() => {
    // Check cookies for latitude and longitude
    const cookieLat = getCookie('latitude');
    const cookieLng = getCookie('longitude');

    if (cookieLat && cookieLng) {
      // If latitude and longitude are found in cookies, use them
      setPosition([parseFloat(cookieLat), parseFloat(cookieLng)]);
    } else if (currentLatitude && currentLongitude) {
      // Use the passed props if cookies are not found
      setPosition([currentLatitude, currentLongitude]);
    } else if (navigator.geolocation) {
      // Fall back to geolocation if cookies and props are not available
      navigator.geolocation.getCurrentPosition((pos) => {
        const { latitude, longitude } = pos.coords;
        setPosition([latitude, longitude]);
        // Optionally, store the position in cookies
        document.cookie = `latitude=${latitude};path=/`;
        document.cookie = `longitude=${longitude};path=/`;
      });
    }
  }, [currentLatitude, currentLongitude]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch nearest stops and places in parallel
        const [stopsResponse, placesResponse] = await Promise.all([
          fetch(
            `https://busparrot.com/pis/api/v1/getstopsaround.php?center_lat=${position[0]}&center_lng=${position[1]}&radius=10`
          ),
          fetch(
            `https://busparrot.com/pis/api/v1/getplacesaround.php?center_lat=${position[0]}&center_lng=${position[1]}&radius=10`
          ),
        ]);

        if (stopsResponse.ok && placesResponse.ok) {
          const [stopsData, placesData] = await Promise.all([
            stopsResponse.json(),
            placesResponse.json(),
          ]);

          if (mapRef.current) {
            // Clear the previous layers
            stopsLayer.current.clearLayers();
            placesLayer.current.clearLayers();

            // Add markers for the fetched stops using the redparrot icon
            const stops = stopsData.stops;
            stops.forEach((stop) => {
              const stopMarker = L.marker([stop.stop_lat, stop.stop_lon], {
                icon: L.icon({
                  iconUrl: redparrot,
                  iconSize: [40, 40],
                }),
              }).bindPopup(stop.stop_name);
              stopsLayer.current.addLayer(stopMarker);
            });

            // Add markers for the fetched places using the redparrot icon
            const places = placesData.places;
            places.forEach((place) => {
              const placeMarker = L.marker([place.lat, place.lon], {
                icon: L.icon({
                  iconUrl: place1,
                  iconSize: [20, 20],
                }),
              }).bindPopup(place.title);
              placesLayer.current.addLayer(placeMarker);
            });

            // Add the updated layers to the map
            stopsLayer.current.addTo(mapRef.current);
            placesLayer.current.addTo(mapRef.current);

            // Log the places data to the console
            console.log('Places Response:', placesData);
          }
        } else {
          console.error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call fetchData when the map is loaded or when user location is set
    if (position[0] && position[1]) {
      fetchData();
    }
  }, [position]);

  useEffect(() => {
    if (position[0] && position[1]) {
      if (!mapRef.current) {
        const map = L.map('map').setView(position, 13);
        mapRef.current = map;
  
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);
  
        const startIcon = L.icon({
          iconUrl: StartIcon,
          iconSize: [64, 64],
        });
  
        const stopIcon = L.icon({
          iconUrl: StopIcon,
          iconSize: [64, 64],
        });
  
        const startMarker = L.marker([position[0], position[1]], {
          icon: startIcon,
        }).addTo(map);
  
        const destination = calculateDestinationTenMetersAway(position);
  
        if (!destinationMarkerRef.current.stopMarker) {
          const stopMarker = L.marker([destination[0], destination[1]], {
            draggable: true,
          }).addTo(map);
  
          destinationMarkerRef.current = { startMarker, stopMarker };
  
          const routingControl = L.Routing.control({
            waypoints: [
              {
                latLng: L.latLng(position[0], position[1]),
              },
              {
                latLng: L.latLng(destination[0], destination[1]),
              },
            ],
            createMarker: function (i, waypoint, n) {
              const icon = i === 0 ? startIcon : stopIcon;
              return L.marker(waypoint.latLng, { icon: icon, draggable: i === n - 1 });
            },
            show: false,
          }).addTo(map);
  
          routingControlRef.current = routingControl;
  
          routingControl.on('routesfound', function (e) {
            const waypoints = e.routes[0].coordinates;
  
            // Log the path points
            console.log('Path points:', waypoints);
  
            // Convert waypoints to a GeoJSON line
            const line = turf.lineString(waypoints.map(point => [point.lng, point.lat]));
  
            // Buffer the line to create a polygon with a 50-meter width
            const buffered = turf.buffer(line, 0.05, { units: 'kilometers' });
  
            // Convert the buffered polygon to Leaflet and add it to the map
            const bufferedPolygon = L.geoJSON(buffered, {
              style: {
                color: '#3388ff',
                weight: 2,
                opacity: 1,
                fillOpacity: 0.2,
              },
            }).addTo(mapRef.current);
  
            // If you want to fit the map bounds to the polygon
            mapRef.current.fitBounds(bufferedPolygon.getBounds());
          });
  
          stopMarker.on('dragend', (e) => {
            const newDestination = e.target.getLatLng();
  
            mapRef.current.removeLayer(stopMarker);
  
            const newStopMarker = L.marker([newDestination.lat, newDestination.lng], {
              draggable: true,
            }).addTo(mapRef.current);
  
            destinationMarkerRef.current = { startMarker, stopMarker: newStopMarker };
  
            routingControl.setWaypoints([
              { latLng: L.latLng(position[0], position[1]) },
              { latLng: newDestination },
            ]);
          });
        }
      }
    }
  }, [position]);
  

  return <div id="map" style={{ width: '100%', height: '400px' }} />;
};

function calculateDestinationTenMetersAway(currentCoordinates) {
  const earthRadius = 6371000;
  const bearing = Math.random() * 360;
  const distance = 1000;

  const [latitude, longitude] = currentCoordinates.map((coord) => (coord * Math.PI) / 180);

  const newLatitude = Math.asin(
    Math.sin(latitude) * Math.cos(distance / earthRadius) +
      Math.cos(latitude) * Math.sin(distance / earthRadius) * Math.cos(bearing)
  );

  const newLongitude =
    longitude +
    Math.atan2(
      Math.sin(bearing) * Math.sin(distance / earthRadius) * Math.cos(latitude),
      Math.cos(distance / earthRadius) - Math.sin(latitude) * Math.sin(newLatitude)
    );

  return [newLatitude * (180 / Math.PI), newLongitude * (180 / Math.PI)];
}

export default Map;
